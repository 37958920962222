import React from "react"
import { Link } from "gatsby"


const OtherCaseStudy = () => (

    <div className="other-case-study py60 container">
        <h2>Diğer başarı hikayelerimiz.</h2>
        <div className="studies">
            <div className="study-box">
                <h3>Liva Pastacılık</h3>
                <p>Dijital pazarlama danışmanlığı, arama motoru optimizasyonu ve reklam hizmeti verdiğimiz e-ticaret markamız.</p>
                <div className="boxs">
                    <div className="box">
                        <span>%5.79</span>
                        <p>E-Ticaret ciro dönüşüm oranı</p>
                    </div>
                    <div className="box">
                        <span>+4.500</span>
                        <p>'den fazla aylık sipariş adeti</p>
                    </div>
                </div>

                <Link to="/basari-hikayeleri/liva-pastacilik/">Başarı hikayesine git</Link>
            </div>
            <div className="study-box">
                <h3>TCDD Taşımacılık</h3>
                <p>Website UI/UX tasarım, yazılım geliştirme ve arama motoru optimizasyonu (SEO) hizmeti verdiğimiz markamız.</p>
                <div className="boxs">
                    <div className="box">
                        <span>+%24</span>
                        <p>Dönüşüm oranı artışı.</p>
                    </div>
                    <div className="box">
                        <span>+%125</span>
                        <p>Organik trafik artışı.</p>
                    </div>
                </div>

                <Link to="/basari-hikayeleri/tcdd-tasimacilik/">Başarı hikayesine git</Link>
            </div>
        </div>
    </div>
)

export default OtherCaseStudy
