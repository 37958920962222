import React, { Component } from "react";
import rightArrow from "../assets/img/right-arrow.png";
import checkModal from "../assets/img/check-modal.png";
import checkup2 from "../assets/img/checkup2.png";

class Checkup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        e.preventDefault();
        this.setState({ value: e.target.value });
    }

    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }

    closeModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.remove('active-modal');
    }

    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();  
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_157540": document.querySelector('#tel').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });

        response.then(res => res.json())
            .then(data => {
                if (data.success) {
                    // Form başarıyla gönderildiğinde gösterilecek mesaj
                    document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
                } else {
                    // Aynı bilgilerle form tekrar gönderilmeye çalışıldığında özel mesaj
                    if (data.message.includes("This form is no longer accepting new submissions per Hour")) {
                        document.querySelector('.status').innerHTML = "Aktif bir form gönderiniz<br> mevcuttur,lütfen daha sonra tekrar deneyiniz.";
                    } else {
                        // Diğer hatalar için genel hata mesajı
                        document.querySelector('.status').innerHTML = "Bir hata oluştu: " + data.message;
                    }
                }
            })
            .catch(error => {
                // Ağ hatası gibi durumlar için hata mesajı
                document.querySelector('.status').innerHTML = "Bir hata oluştu: " + error.message;
            });
    }

    render() {
        return (
            <div className="checkup checkup-index py60 container">
                <div className="col-md-6">
                    <h2 className="check-head">Websiteniz için <br />ücretsiz rapor sunalım.</h2>
                    <p>Müşterilerinizle buluştuğunuz ve ilk izlenimde etkili olan websitenizin performansını analiz edelim ve sonuçları size rapor edelim.</p>
                    <input type="text" className="pink-input" placeholder="Websitenizi giriniz." value={this.state.value} onChange={this.handleChange} />
                    <button id="checkupIndexButton" className="red-button" onClick={(e) => this.showModal()}>Ücretsiz rapor<img alt="alt text" src={rightArrow} /></button>
                </div>
                <div className="col-md-6">
                    <img alt="alt text" src={checkup2} />
                </div>
                <div className="check-modal">
                    <span onClick={(e) => this.closeModal()}>X</span>
                    <h2 className="form-head">Formu doldurun.</h2>
                    <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                    <div className="check-modal_container">
                        <form onSubmit={this.handleSubmit.bind(this)} >
                            <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus={true} name="text_204202" id="site" required placeholder="Websitenizi girin." />
                            <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                            <input
                                type="text"
                                id="tel"
                                required
                                name="text_157540"
                                placeholder="Telefon girin."
                                onChange={(e) => {
                                    const value = e.target.value.replace(/[^0-9+]/g, ''); // Harfleri engelle ve sadece sayı ve + işaretine izin ver
                                    e.target.value = value; // Değeri güncelle
                                }}
                            />
                            <input type="email" id="email" required name="text_532192" placeholder="E-posta adresinizi girin." />
                            <button>GÖNDER</button>
                            <br></br>
                            <div className="status"></div>
                        </form>
                        <img alt="alt text" src={checkModal} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Checkup;
